/* Alt.css */
.post {
  position: relative;
  display: inline-block;
}

.title-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white; /* Text color */
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 8px; /* Reduced font size */
  font-weight: bold;
  pointer-events: none; /* Ensures the text doesn't interfere with the image click */
}
