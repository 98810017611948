/* Navigation Bar Styles */
.navbarsubtop {
  width: 200px;
  height: 20px;
  background-color: black;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  color: white;
  }
  
  /* Links inside the navigation bar */
  .navbarsubtop a {
    float: left;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
  }
  
  /* Change color of links on hover */
  .navbarsubtop a:hover {
    background-color: #555; /* Change this to your desired hover color */
  }
  
  /* Add active class to the current link */
  .navbarsubtop a.active {
    background-color: #666; /* Change this to your desired active link color */
  }
  
  /* Center the navigation links */
  .navbarsubtop a.center {
    float: none;
    display: inline-block;
  }
  
  /* Clear floats after the navbarsubtop */
  .navbarsubtop::after {
    content: "";
    clear: both;
    display: table;
  }
  