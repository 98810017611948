/* App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
}

.container {
  padding: 0;
}

.header {
  background-color: #fff;
  text-align: center;
}

.header h1 {
  font-size: 24px;
  margin: 0;
}

.post {
  background-color: #fff;
  max-width: 100%; /* Ensure the post container can expand to the full width */
}

.post img {
  overflow: hidden; /* Hide overflow content */
  width: 300px; /* Set a specific width for the container */
  height: 200px; /* Set a specific height for the container */
  display: block;
}

.post .post-info h2 {
  font-size: 11px;
  margin: 0;
}

.post .post-info p {
  font-size: 9px;
  color: #555;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .post {
    border: none;
  }
}

/* Reset default margin and padding */
body, html {
  margin: 0;
  padding: 0;
}

/* Center the content horizontally */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 20px;
}

/* Define styles for each row */
.row {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

/* Adjust dimensions and styles for individual components */
.component {
  width: 200px;
  height: 200px;
  background-color: black;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  color: white;
}

/* Example component image style */
.component img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  object-fit: cover;
}
