/* Navigation Bar Styles */
.navbarbottom {
    background-color: #333; /* Change this to your desired background color */
    overflow: hidden;
  }
  
  /* Links inside the navigation bar */
  .navbarbottom a {
    float: left;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
  }
  
  /* Change color of links on hover */
  .navbarbottom a:hover {
    background-color: #555; /* Change this to your desired hover color */
  }
  
  /* Add active class to the current link */
  .navbarbottom a.active {
    background-color: #666; /* Change this to your desired active link color */
  }
  
  /* Center the navigation links */
  .navbarbottom a.center {
    float: none;
    display: inline-block;
  }
  
  /* Clear floats after the navbarbottom */
  .navbarbottom::after {
    content: "";
    clear: both;
    display: table;
  }
  