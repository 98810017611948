/* App.css */

/* App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
}

.container {
  /*max-width: 960px;*/
  /*margin: 0 auto;*/
  padding: 0px; /*20*/
}

.header {
  background-color: #fff;
  /*
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  */

  text-align: center;
}

.header h1 {
  font-size: 24px;
  margin: 0;
}

.post {
  background-color: #fff;
  /*
  border: 1px solid #ddd;
  margin-bottom: 20px;
  */

  max-width: 96px;
}

.post img {
  width: 100%;
  display: block;
}

.post .post-info {
  /*padding: 10px;*/
}

.post .post-info h2 {
  font-size: 11px;
  margin: 0;
}

.post .post-info p {
  font-size: 9px;
  color: #555;
}

@media (max-width: 768px) {
  .container {
    /*padding: 10px;*/
  }

  .post {
    border: none;
    /*
    border-bottom: 1px solid #ddd;
    */
  }
}





/* Reset default margin and padding */
/*
body, html {
  margin: 0;
  padding: 0;
}

/* Center the content horizontally */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 20px;
}

/* Define styles for each row */
.row {
  display: flex;
  justify-content: space-around;
  width: 100%;
  /*margin-bottom: 20px;*/
}

/* Adjust dimensions and styles for individual components */
/* You may need to customize these styles based on the appearance of your components */

/* Example component style */
.component {
  width: 200px;
  height: 200px;
  background-color: black;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  color: white;
}


/* Example component image style */
/*
.component img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  object-fit: cover;
}
*/
/* Adjust dimensions and styles as needed */
